<template>
  <div>
    <h3>Oops, looks like something went wrong!</h3>
    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn--primary">
      Close
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmissionFail",
};
</script>
