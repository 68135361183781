<template>
  <div>
    <h4>Thanks for reaching out; Bonnie will get back to you soon!</h4>
    <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn--primary">
      Close
    </button>
  </div>
</template>

<script>
export default {
  name: "SubmissionSuccess",
};
</script>

<style lang="scss" scoped>
h6 {
  margin-bottom: 1.5rem;
}
</style>
