import { createApp } from "vue";
import { createPinia } from "pinia";
//import axios from "axios";
//import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";

// import "bootstrap/dist/css/bootstrap.min.css"
import "@/assets/scss/vendors/bootstrap/index.scss"; //Custom Bootstrap
import "bootstrap";

// import '../src/assets/scss/base.scss';
const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
//app.use(VueAxios, axios);
app.provide("axios", app.config.globalProperties.axios); // provide 'axios'

app.use(router).mount("#app");
