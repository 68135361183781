<template>
  <HeroSecondary
    :anchor="'home'"
    :class="''"
    :titleShow="false"
    :title="'Performer'"
    :imageUrl="'hero_bonnie.jpg'"
    :alt="'Image of Bonnie with a Violin'"
    :description="description"
  />
  <BonnieServices></BonnieServices>
  <RequestForm />
</template>

<script>
// @ is an alias to /src
import HeroSecondary from "@/components/heroSecondary.vue";

import BonnieServices from "@/components/bonnieServices.vue";
import RequestForm from "@/components/requestForm.vue";

export default {
  name: "HomeView",

  components: {
    HeroSecondary,
    BonnieServices,
    RequestForm,
  },
  props: {
    // visible: {
    //   required: true,
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      state_isVisible: false,
      mobileSize: 992,
      description: `Bonnie has played the violin since she was five years old after seeing a guest performance on Mr. Roger’s Neighborhood. Born and raised in Mesa, she came from a musical family who recognized the importance of private lessons from a young age. Bonnie’s love for the violin continued to grow through her school years. She made Regionals all four years, Arizona All-State Orchestra three years, participated in Solo & Ensemble festivals and attended MasterWorks Music Festival. Bonnie went on to major in Violin Performance Degree and graduated Suma Cum Laude from ASU in 2008. When not teaching (or playing), Bonnie loves to read, run, hike, and garden.`,
    };
  },
  computed: {},
  created() {
    window.addEventListener("resize", this.isMobileScreenOnLoad());
  },
  unmounted() {
    window.removeEventListener("resize", this.isMobileScreenOnLoad);
  },
  mounted() {
    this.isMobileScreenOnLoad();
  },
  methods: {
    isVisbile() {
      this.state_isVisible = true;
      // console.log(this.state_isVisible);
    },
    notVisible() {
      this.state_isVisible = false;
      // console.log(this.state_isVisible);
    },
    isMobileScreenOnLoad() {
      // this.isMobile = !this.isMobile;
      const windowSize = window.innerWidth;
      if (windowSize >= this.mobileSize) {
        //desktop
        // console.log("Desktop: " + !this.state_isVisible);
        this.notVisible();
      } else {
        // mobile
        this.isVisbile();
        // console.log("mobile: " + this.state_isVisible);
      }
      window.addEventListener("resize", (e) => {
        const targetWindow = e.target.window.innerWidth;
        // console.log(targetWindow);
        // console.log(window.innerWidth)
        if (targetWindow >= this.mobileSize) {
          //desktop
          this.notVisible();
          // console.log("Desktop: " + !this.state_isVisible);
        } else {
          // mobile
          this.isVisbile();
          // console.log("mobile: " + this.state_isVisible);
        }
      });
    },
  },
};
</script>
