<template>
  <HeroSecondary :anchor="'performer'" :class="'hero-main--short'" :titleShow="true" :title="'Performer'"
    :imageUrl="'bonnie_two.jpg'" :alt="'Image of Bonnie with a Violin'" :description="description" />


  <section class="container-sm article-page__body">
    <!-- <BackToTop /> -->
    <div class="article-page__main-text">
      <p>Bonnie has been a member of various orchestras throughout the state of Arizona since 2012. She began with the
        Symphony of the Southwest in 2012 and later joined the West Valley Symphony in 2013. In 2018, she successfully
        auditioned for the Arizona Philharmonic and served as their Associate Concertmaster for five seasons. Bonnie has a
        deep passion for orchestral playing. The experience of hearing 60-100 musicians playing the same piece of music
        and
        striving for excellence in musicianship is both thrilling and deeply satisfying for her.</p>
      <p>
        Bonnie also has a love for fiddling. She started taking fiddle lessons at the age of 12 and still finds the music
        captivating. From 2013 until the spring of 2023, she served as the lead fiddler for Trotters Wake until the
        leaders
        of the band retired and moved to North Carolina. Currently, Bonnie is the lead fiddle player for Finnegan’s
        Brogue,
        a band formed by members of Trotters Wake.

      </p>

      <p>In addition to her orchestral and fiddling pursuits, Bonnie also performs at weddings, memorials, and other
        events
        both as a soloist and alongside a harpist friend. It’s worth noting that Bonnie had the opportunity to perform two
        shows with BarlowGirl during their last tour season in 2010 and was a soloist in the Vivaldi Festival in 2019. She
        combines her love for classical music, enjoyment of fiddling, and empathy to create the perfect playlist for each
        client. To book her services, you can contact her through GigSalad or reach out to her directly. </p>

    </div>
    <div class="article-page__cta">
      <a href="https://www.gigsalad.com/bonnie_blue_music_tempe" target="_blank" class="btn--primary">BOOK ON
        GIG SALAD</a>
      <a href="mailto:bonnie@bonniebluemusic.com?subject=Requesting%20More%20Information" target="_blank"
        class="btn btn--primary">SEND AN EMAIL</a>
    </div>


  </section>
</template>

<script>
import HeroSecondary from "@/components/heroSecondary.vue";
// import BackToTop from "@/shared/backToTop.vue";

export default {
  name: "PerformerView",

  data() {
    return {
      description: ``,
    };
  },

  components: {
    HeroSecondary,
    // BackToTop,
  },
  beforeCreate() {
    document.body.classList.add('article-page')
  },

  beforeUnmount() {
    document.body.classList.remove('article-page')
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";
</style>