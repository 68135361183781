<template>
  <header class="nav-main" data-nav id="nav">
    <nav class="nav-main__container container-xl" data-nav-sticky="show-scroll-down">
      <button class="menu-btn" @click="navToggle">
        <div class="menu-btn__icon"></div>
      </button>
      <div class="nav-main__logo">
        <router-link to="/">
          <img src="@/assets/logo/PNG/logo-icon.png" width="54" alt="Bonnie Blue Music Logo" />
          <span>BONNIE TEPLIK</span>
        </router-link>
      </div>
      <a class="nav-main__link nav-main__link--fb" href="https://www.facebook.com/BonnieBlueMusicLessons/"
        title="Bonnie Teplik Facebook" target="_blank">
        <span class="icon icon--primary">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style="enable-background: new 0 0 128 128"
            width="24px">
            <path d="M115.5,0.1H12.5C5.7,0.1,0.1,5.7,0.1,12.5v103.1c0,6.8,5.5,12.3,12.3,12.3h49.3c1.9,0,3.4-1.5,3.4-3.4
s-1.5-3.4-3.4-3.4H12.5c-3.1,0-5.6-2.5-5.6-5.6V12.5c0-3.1,2.5-5.6,5.6-5.6h103.1c3.1,0,5.6,2.5,5.6,5.6v103.1
c0,3.1-2.5,5.6-5.6,5.6H92V83h12.3c1.7,0,3.1-1.3,3.3-2.9l2.2-17.9c0.1-1-0.2-1.9-0.8-2.6c-0.6-0.7-1.6-1.1-2.5-1.1H92V43.8
c0-0.6,0.5-1.1,1.1-1.1h13.4c1.9,0,3.4-1.5,3.4-3.4V21.4c0-1.9-1.5-3.4-3.4-3.4H88.6c-11.7,0-21.3,9.5-21.3,21.3v19H52.8
c-1.9,0-3.4,1.5-3.4,3.4v17.9c0,1.9,1.5,3.4,3.4,3.4h14.6v41.5c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4V79.7c0-1.9-1.5-3.4-3.4-3.4
H56.2V65.1h14.6c1.9,0,3.4-1.5,3.4-3.4V39.4c0-8,6.5-14.6,14.6-14.6h14.6V36H93.1c-4.3,0-7.8,3.5-7.8,7.8v17.9
c0,1.9,1.5,3.4,3.4,3.4h14.1l-1.4,11.2H88.6c-1.9,0-3.4,1.5-3.4,3.4v44.8c0,1.9,1.5,3.4,3.4,3.4c0,0,0,0,0,0s0,0,0,0h26.9
c6.8,0,12.3-5.5,12.3-12.3V12.5C127.9,5.7,122.3,0.1,115.5,0.1z" />
          </svg>
        </span>
      </a>
      <a href="mailto:bonnie@bonniebluemusic.com?subject=Requesting%20More%20Information" target="_blank">
        <span class="icon icon--primary">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="enable-background: new 0 0 24 24"
            width="24px">
            <path d="M22.4,2.5H1.6C0.7,2.5,0,3.2,0,4.1v15.6c0,0.9,0.7,1.6,1.6,1.6h20.7c0.9,0,1.6-0.7,1.6-1.6V4.1C24,3.2,23.3,2.5,22.4,2.5z M2.2,3.7h19.7c0.3,0,0.6,0.2,0.7,0.4l-10.1,7.2c-0.4,0.3-1,0.3-1.4,0L1.4,4.2C1.5,3.9,1.8,3.7,2.2,3.7z M22.8,19.2c0,0.5-0.4,1-1,1
H2.2c-0.5,0-1-0.4-1-1V5.5l9.9,7.3c0.4,0.3,1,0.3,1.4,0l10.2-7.4c0,0,0,0,0,0V19.2z" />
          </svg>
        </span>
      </a>
    </nav>
    <div class="btp-btn" @click="scrollToTop">
      <button class="btp-btn__btn" data-btn="Top">
        <span>^</span>
        <span class="btp-btn__label">back to top</span>
      </button>
    </div>
  </header>
  <div class="offcanvas-menu__backdrop" @click="navToggle" v-if="show"></div>
  <Transition name="slide-fade">
    <div class="offcanvas-menu" v-if="show">
      <div class="offcanvas-menu__header">
        <div class="offcanvas-menu__logo">
          <router-link @click="navToggle" to="/">
            <img src="@/assets/logo/PNG/logo-icon.png" width="54" alt="Bonnie Blue Music Logo" />
          </router-link>
        </div>
        <button type="button" class="btn-close text-reset" @click="navToggle" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <nav class="offcanvas-menu__nav">
          <ul>
            <li v-for="(item, $index) in navItems" v-bind:key="$index">
              <router-link :to="{ name: item.name, hash: item.hash }" @click="navToggle">
                {{ item.label }}
              </router-link>
            </li>
            <li>
              <router-link to="/performer" @click="navToggle">
                Performer
              </router-link>
            </li>
            <li>
              <router-link to="/teacher" @click="navToggle">
                Teacher
              </router-link>
            </li>
            <!-- <li><a href="#" @click="navToggle">Back To Top</a></li> -->
          </ul>
        </nav>
      </div>
    </div>
  </Transition>
</template>

<script>
import { useNavStore } from "@/store/nav-store";
// import shared from "@/shared";

export default {
  el: "navMenu",

  setup() {
    const mainNavStore = useNavStore();
    const body = document.querySelector("body");
    return { mainNavStore, body };
  },
  // How do you manage state.
  data() {
    return {
      show: false,
    };
  },

  computed: {
    navItems() {
      return this.mainNavStore.navCollection;
    },

    // subItemsCollection() {
    //   return this.mainNavStore.subItemsCollection;
    // },
  },

  methods: {
    isStickyNav() {
      // Get Static Sibling Element.
      const staticEl = document.querySelector("header");
      // const navOffset = staticEl * staticEl;
      const stickyEl = document.querySelector(".nav-main");
      const stickyOrientation = stickyEl.getAttribute("data-nav-sticky");
      let lastScrollTop = window.scrollY;
      const hideNav = () => {
        window.setTimeout(() => {
          stickyEl.parentNode.parentNode.style.padddingTop = "0px"
          stickyEl.style.top = "-" + stickyEl.offsetHeight + "px";
          stickyEl.style.opacity = "0";
          stickyEl.classList.remove("isSticky");
        }, 100);
      };
      const showNav = () => {
        window.setTimeout(() => {
          stickyEl.parentNode.parentNode.style.padddingTop = 84 + "px"
          stickyEl.style.top = "0";
          stickyEl.style.opacity = "1";
        }, 100);
      };
      if (window.scrollY <= staticEl.offsetHeight * 4) {
        showNav();
      } else {
        showNav();
      }
      window.addEventListener("scroll", () => {
        let currenScrollPos =
          window.scrollY || document.documentElement.scrollTop;
        if (window.scrollY >= staticEl.offsetHeight * 4) {
          stickyEl.classList.add("isSticky");
          if (currenScrollPos == staticEl.offsetHeight * 4) {
            stickyEl.classList.remove("isSticky");
          }
          if (currenScrollPos >= lastScrollTop) {
            // downscroll
            if (stickyOrientation === "show-scroll-down") {
              showNav();
            } else {
              hideNav();
            }
          } else {
            // upscroll
            if (stickyOrientation === "show-scroll-up") {
              hideNav();
            } else {
              showNav();
            }
          }
        } else {
          showNav();
          stickyEl.classList.remove("isSticky");
        }
        // Mobile or Negative Scrolling
        lastScrollTop = currenScrollPos <= 0 ? 0 : currenScrollPos;
      });
    },
    openMenu() {
      // console.log('open')
      this.body.style.overflow = "hidden";
      this.body.style.height = "100%";
    },
    closeMenu() {
      // console.log('closed')
      this.body.style.overflow = "";
      this.body.style.height = "";
    },
    navToggle() {
      this.show = !this.show;
      // console.log(this.show)
      if (this.show) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  },

  mounted() {
    this.scrollToTop();
    this.isStickyNav();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vendors/bootstrap/custom";

.btp-btn {
  @include media-breakpoint-up(lg) {
    display: none;
  }


  &__btn {
    position: absolute;
    right: 0;
    width: 25px;
    height: 25px;
    background-color: $primary;
    color: $white;
    border: 0;
  }

  &__label {
    display: none;
  }

}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 250ms ease-in-out;
}

.slide-fade-leave-active {
  transition: all 250ms ease-in-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

// Nav
nav {
  a {
    font-weight: 600;
    color: $black;
    transition: all 300ms ease-in-out;

    // &:hover {
    //   color: $primary;
    //   padding-left: 0.5rem;
    //   border-left: 3px solid $primary;
    // }
  }
}

// Top Nav
.nav-main {
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: 999;
  transition: all 300ms ease-in-out;

  &.isSticky {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 00.5);
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 15px 15px;
    color: $primary;

    a {
      color: $primary;
    }

    * {
      margin-right: 5px
    }
  }

  &__logo {
    display: none;
  }

  &__link {
    &--fb {
      @include media-breakpoint-down(lg) {
        // display: none;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    &__logo {
      display: block;
      // width: 150px;

      img {
        display: inline-block;
        // width: 100%;
      }
    }
  }
}

.offcanvas-menu {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;

  width: 75%;
  height: 100%;
  max-width: 400px;
  padding: 2rem;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 999;

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba($black, 00.5);
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__logo {
    width: 150px;

    img {
      display: block;
      width: 100%;
      max-width: 50%;
    }
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    transition: transform 500ms ease-in-out;

    &:hover {
      transform: rotate(180deg);
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;

    // font-size: 1.6rem;
    a {
      text-decoration: none;
    }

    img {
      width: 20px;
      height: 20px;
      margin-right: 0.5rem;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      ul {
        padding-left: 1.25rem;
      }
    }

    li {
      margin-bottom: 1rem;
    }
  }
}

.menu-btn {
  $gp: &;
  $hamburger-icon-ht: 3px;
  $hamburger-icon-clr: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20px;
  height: 20px;
  border: none;
  padding: 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: transparent;

  &__icon {
    width: 100%;
    height: $hamburger-icon-ht;
    background-color: $hamburger-icon-clr;
    margin: 0;
  }

  &::before,
  &::after {
    display: block;
    content: "";
    width: 100%;
    height: $hamburger-icon-ht;
    background-color: $hamburger-icon-clr;
    transition: transform 300ms ease-in-out;
  }

  &:hover {
    #{$gp}__icon {
      transform: translateX(-4px);
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}
</style>
