import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import PerformerView from "../views/PerformerView.vue";
import TeacherView from "../views/TeacherView.vue";

const routes = [
  {
    name: "Home",
    path: "/",
    component: HomeView,
    meta: { transition: "slide-left" },
  },
  {
    name: "Performer",
    path: "/performer",
    component: PerformerView,
    meta: { transition: "slide-left" },
  },
  {
    name: "Teacher",
    path: "/teacher",
    component: TeacherView,
    meta: { transition: "slide-left" },
  },

  
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return new Promise((resolve) => {
          const windowWidth = window.innerWidth >= 768 ? 82 : 50;
          setTimeout(() => {
            resolve({
              el: to.hash,
              behavior: "smooth",
              // top: 0 //return the offset here if condition
              top: windowWidth, //Offset height of nav
            });
          }, 500);
        });
      } else {
        return { top: 0 };
      }
    }
  },
});



export default router;
