<template>
  <section class="footerSec" id="section8">
    <div class="container-xl">
      <div class="row mid-section">
        <div class="col-sm-6 footerSec__col-one">
          <ul class="">
            <li><span>BONNIE TEPLIK</span></li>
            <li>
              <a href="mailto:bonnie@bonniebluemusic.com?subject=Requesting%20More%20Information" target="_blank">
                <span class="icon icon--white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="enable-background: new 0 0 24 24"
                    width="24px">
                    <path d="M22.4,2.5H1.6C0.7,2.5,0,3.2,0,4.1v15.6c0,0.9,0.7,1.6,1.6,1.6h20.7c0.9,0,1.6-0.7,1.6-1.6V4.1C24,3.2,23.3,2.5,22.4,2.5z M2.2,3.7h19.7c0.3,0,0.6,0.2,0.7,0.4l-10.1,7.2c-0.4,0.3-1,0.3-1.4,0L1.4,4.2C1.5,3.9,1.8,3.7,2.2,3.7z M22.8,19.2c0,0.5-0.4,1-1,1
	H2.2c-0.5,0-1-0.4-1-1V5.5l9.9,7.3c0.4,0.3,1,0.3,1.4,0l10.2-7.4c0,0,0,0,0,0V19.2z" />
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/BonnieBlueMusicLessons/" title="Bonnie Blue Music Lessons Facebook"
                target="_blank">
                <span class="icon icon--white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style="enable-background: new 0 0 128 128"
                    width="24px">
                    <path d="M115.5,0.1H12.5C5.7,0.1,0.1,5.7,0.1,12.5v103.1c0,6.8,5.5,12.3,12.3,12.3h49.3c1.9,0,3.4-1.5,3.4-3.4
	s-1.5-3.4-3.4-3.4H12.5c-3.1,0-5.6-2.5-5.6-5.6V12.5c0-3.1,2.5-5.6,5.6-5.6h103.1c3.1,0,5.6,2.5,5.6,5.6v103.1
	c0,3.1-2.5,5.6-5.6,5.6H92V83h12.3c1.7,0,3.1-1.3,3.3-2.9l2.2-17.9c0.1-1-0.2-1.9-0.8-2.6c-0.6-0.7-1.6-1.1-2.5-1.1H92V43.8
	c0-0.6,0.5-1.1,1.1-1.1h13.4c1.9,0,3.4-1.5,3.4-3.4V21.4c0-1.9-1.5-3.4-3.4-3.4H88.6c-11.7,0-21.3,9.5-21.3,21.3v19H52.8
	c-1.9,0-3.4,1.5-3.4,3.4v17.9c0,1.9,1.5,3.4,3.4,3.4h14.6v41.5c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4V79.7c0-1.9-1.5-3.4-3.4-3.4
	H56.2V65.1h14.6c1.9,0,3.4-1.5,3.4-3.4V39.4c0-8,6.5-14.6,14.6-14.6h14.6V36H93.1c-4.3,0-7.8,3.5-7.8,7.8v17.9
	c0,1.9,1.5,3.4,3.4,3.4h14.1l-1.4,11.2H88.6c-1.9,0-3.4,1.5-3.4,3.4v44.8c0,1.9,1.5,3.4,3.4,3.4c0,0,0,0,0,0s0,0,0,0h26.9
	c6.8,0,12.3-5.5,12.3-12.3V12.5C127.9,5.7,122.3,0.1,115.5,0.1z" />
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-sm-6 footerSec__col-three">
          <div>
            <a href="#">Back to Top</a>
            |
            <a data-bs-toggle="modal" data-bs-target="#privacyPolicyModal" class="privacy-policy">Privacy Policy</a>
          </div>
          <div class="boring-text">
            <span>©{{ year }} Bonnie Teplik. All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
    <PrivacyPolicy></PrivacyPolicy>
  </section>
</template>

<script>
import { useNavStore } from "@/store/nav-store";
import PrivacyPolicy from "@/shared/privacyPolicy.vue";

export default {
  setup() {
    const mainNav = useNavStore();
    const subItems = mainNav.navItems[0].subItems;
    return {
      subItems,
    };
  },
  data() {
    return {
      year: this.getYear(),
    };
  },
  components: {
    PrivacyPolicy,
  },
  methods: {
    getYear() {
      const year = new Date().getFullYear();
      return year;
    },
  },
  computed: {},
  mounted() {
    // const year = this.getYear()
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom.scss";

.footerSec {
  background-color: $primary;
  padding: 4rem 0;
  color: white;
  line-height: 2rem;

  a {
    color: white;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }

  .icon-text {
    @media screen and (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .icon--instagram {
    @media screen and (max-width: $screen-sm-max) {
      margin-right: 0;
      padding-right: 1rem;
    }
  }

  .boring-text {
    font-size: x-small;
  }

  .footer-logo {
    max-width: 350px;
    width: 100%;
    // margin-bottom: 1.5rem;

    @media screen and (max-width: $screen-sm-max) {
      margin-bottom: 0;
    }
  }

  &__tagline {
    text-transform: capitalize;
    font-weight: 500;
    font-family: "Barlow Condensed";
    padding-left: 18%;
    margin-top: -1rem;

    @media screen and (max-width: $screen-sm-max) {
      padding-left: 0;
    }
  }

  .mid-section {
    justify-content: space-between;

    @media screen and (max-width: $screen-sm-max) {}
  }

  &__col-one {
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // line-height: 1.5;

    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      @media screen and (max-width: $screen-sm-max) {
        justify-content: center;
      }

      li {
        margin-right: 1.5rem;

        @media screen and (max-width: $screen-sm-max) {
          margin-bottom: 0;
          padding-right: 1rem;
        }

        &:last-child {
          margin-bottom: 0;

          @media screen and (max-width: $screen-sm-max) {
            padding-right: 0;
          }
        }
      }
    }

    @media screen and (max-width: $screen-sm-max) {
      order: 1;
    }
  }

  &__col-two {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    // &:before,
    // &:after {
    //   display: inline-block;
    //   content: "";
    //   width: 20%;
    //   border-top: 0.1rem solid white;

    //   @media screen and (max-width: $screen-md-max) {
    //     margin: 1rem 0;
    //   }
    // }

    @media screen and (max-width: $screen-sm-max) {
      order: 1;
      margin-bottom: 1.5rem;
    }
  }

  &__col-three {
    // line-height: 1.5rem;
    text-align: right;

    @media screen and (max-width: $screen-sm-max) {
      order: 2;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}
</style>
