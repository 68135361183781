<template>
  <section class="container-xl service-card">
    <div class="service-card__container">
      <div class="card">
        <div class="card__head">
          <img
            src="@/assets/images/home-page/bonnie_two.jpg"
            alt="Bonnie playing the violin in the desert"
          />
          <h2>PERFORMER</h2>
        </div>
        <div class="card__content">
          <p>
            Bonnie has performed with BarlowGirl and has soloed for the Vivaldi
            Festival. She currently plays with
            <a
              href="https://westvalleysymphony.org/"
              title="West Valley Symphony"
              target="_blank"
              >West Valley Symphony</a
            >
            and has also played with
            <a
              href="http://symphonyofthesouthwest.org/"
              title="Symphony of the Southwest"
              target="_blank"
              >Symphony of the Southwest</a
            >
            and was Associate Concertmaster of the
            <a
              href="https://azphil.org/"
              title="Arizona Philharmonic"
              target="_blank"
              >Arizona Philharmonic</a
            >
            for five seasons. She was the primary fiddler for Trotters Wake
            Irish Band until the leaders retired, and currently plays with
            <a
              href="https://finnegansbrogue.com/"
              title="Finnegan’s Brogue"
              target="_blank"
              >Finnegan’s Brogue</a
            >.
          </p>
          <div class="card__read-more">
            <router-link to="/performer"> Read More... </router-link>
          </div>
          <a
            href="https://www.gigsalad.com/bonnie_blue_music_tempe"
            target="_blank"
            class="btn--primary card__cta"
            >BOOK ON GIG SALAD</a
          >
        </div>
      </div>
      <div class="card">
        <div class="card__head">
          <img
            src="@/assets/images/home-page/bonnie_three.jpg"
            alt="Bonnie playing the violin"
          />
          <h2>TEACHER</h2>
        </div>
        <div class="card__content">
          <p>
            Bonnie has been teaching private students since 2008.She believes
            performing & teaching are firmly intertwined. Because she loves to
            play, she loves to teach students the joy of playing! Her students
            have successfully auditioned for
            <a
              href="https://www.evysaz.org/"
              title="East Valley Youth Symphony"
              target="_blank"
              >East Valley Youth Symphony</a
            >,
            <a
              href="https://www.azmys.org/"
              title="Metropolitan Youth Symphony"
              target="_blank"
              >Metropolitan Youth Symphony</a
            >, and
            <a
              href="https://pysorchestras.org/"
              title="Phoenix Youth Symphony"
              target="_blank"
              >Phoenix Youth Symphony</a
            >. Whatever your age or experience level, Bonnie supports all stages
            of your musical journey.
          </p>
          <div class="card__read-more">
            <router-link to="/teacher"> Read More... </router-link>
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#requestForm"
            class="btn--primary card__cta"
          >
            SIGN UP OR LEARN MORE
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.service-card {
  margin-top: var(--section-spacing);
  margin-bottom: var(--section-spacing);

  &__container {
    display: flex;
    gap: calc(var(--grid-spacing) * 2);
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      margin-bottom: calc(var(--grid-spacing));
    }

    // display: grid;
    // grid-template-columns: 1fr 1fr;
  }

  img {
    display: inline-block;
    width: 100%;
  }
}

.card {
  display: flex;
  width: 100%;
  // flex-grow: auto;
  border: 1px solid $gray-500;
  flex-direction: column;
  flex-wrap: nowrap;

  @include media-breakpoint-up(lg) {
    width: 49.3%;
    margin-bottom: calc(var(--grid-spacing));
  }

  &__head {
    position: relative;

    h2 {
      @include offsetTitle($white);
    }

    img {
      max-height: 284px;
      aspect-ratio: 4/3;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: calc(var(--card-spacing));
    height: 100%;

    p {
      text-align: left;
    }
  }

  &__read-more {
    margin-bottom: 1.6rem;

    a {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &__cta {
    max-width: 320px;
    margin: auto auto 0;
  }
}
</style>
x
