<template>
  <div
    class="modal fade"
    id="requestForm"
    tabindex="-1"
    aria-labelledby="requestFormLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="modal-gallery">
            <div class="modal-img">
              <img
                class="img-fluid"
                src="@/assets/images/home-page/bonnie_four.jpg"
                alt=""
              />
            </div>
          </div>
          <div class="modal-title-container">
            <h3 class="modal-title" id="requestFormLabel">
              Sign Up For Lessons or Learn More
            </h3>
          </div>
          <form
            @submit.prevent="sendEmail"
            name="sign-up-for-lessons"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            v-if="!loadingText"
          >
            <input type="hidden" name="form-name" value="sign-up-for-lessons" />

            <div class="form-floating mb-3">
              <input
                v-model="formInputs.name"
                class="form-control"
                id="floatingInputName"
                placeholder="name"
                required
              />
              <label for="floatingInputName">Name</label>
            </div>
            <div class="form-floating mb-3">
              <input
                v-model="formInputs.email"
                class="form-control"
                id="floatingInputEmail"
                placeholder="Email"
                required
              />
              <label for="floatingInputEmail">Email</label>
            </div>
            <div class="form-floating">
              <input
                v-model="formInputs.phone"
                class="form-control"
                id="floatingPhone"
                placeholder="Phone"
                required
              />
              <label for="floatingPhone">Phone</label>
            </div>
            <div class="form-floating">
              <textarea
                v-model="formInputs.address"
                class="form-control"
                placeholder="Address"
                id="floatingAddress"
                style="height: 100px"
                required
              ></textarea>
              <label for="floatingAdress">Cross Streets</label>
            </div>
            <div class="form-floating">
              <textarea
                v-model="formInputs.message"
                class="form-control"
                placeholder="Write your message here"
                id="floatingTextarea2"
                style="height: 100px"
                required
              ></textarea>
              <label for="floatingTextarea2">Message</label>
            </div>
            <button type="submit" class="btn--primary">Send</button>
          </form>
          <div v-else-if="loadingError">
            <submission-failed></submission-failed>
          </div>
          <div v-else>
            <submission-success></submission-success>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import SubmissionFailed from "./submissionFailed.vue";
import SubmissionSuccess from "./submissionSuccess.vue";

export default {
  data() {
    return {
      formInputs: {
        name: "",
        email: "",
        phone: "",
        address: "",
        message: "",
        // endpoint: "https://formspree.io/f/mpznzyvv",
      },
      loadingText: false,
      loadingError: false,
    };
  },
  setup() {},
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    sendEmail() {
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      // this.loadingText = true;
      axios
        .post(
          "/",
          this.encode({
            "form-name": "sign-up-for-lessons",
            ...this.formInputs,
          }),
          axiosConfig
        )
        .then(() => {
          this.loadingText = true;
          this.formInputs.name = "";
          this.formInputs.email = "";
          this.formInputs.phone = "";
          this.formInputs.address = "";
          this.formInputs.message = "";
          // this.loadingText = false;
          //i redirect my app to '/' route once payload completed.
          // this.$router.push({ path: "/" });
        })
        .catch((error) => {
          if (error.response) {
            // eslint-disable-next-line no-alert
            this.loadingError = true;
            alert(error.response.data); // => the response payload
          }
        });
    },
  },
  components: { SubmissionFailed, SubmissionSuccess },
};
</script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.modal-content {
  padding: 0 1.5rem;

  .modal-header {
    // position: relative;
    border: none;
    padding: 0;

    .btn-close {
      position: absolute;
      top: 32px;
      right: 20px;
      z-index: 1;
      background-color: white;
      padding: 0.75rem;
      opacity: 1;
      border-radius: 50%;
    }
  }

  .modal-body {
    padding: 2.5rem 0;
  }

  .threeImgGallery {
    grid-template-rows: repeat(2, 18vh);

    @media screen and (max-width: $screen-lg-max) {
      grid-template-rows: repeat(2, 12vh);
    }
  }

  .modal-title {
    margin: 1rem 0;
  }

  .form-floating {
    margin-bottom: 1rem;

    & > .form-control,
    & > .form-control-plaintext,
    & > .form-select {
      font-size: 1.2rem;
      background-color: #f2f4f8;
      border: none;
      height: calc(4rem + 9.6px);
    }

    & > .form-control-plaintext:focus,
    & > .form-control-plaintext:not(:placeholder-shown),
    & > .form-control:focus,
    & > .form-control:not(:placeholder-shown) {
      padding-top: 2.725rem;
      padding-bottom: 0.625rem;
    }
  }
}
</style>
