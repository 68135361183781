<template>
  <section
    class="container-fluid hero-main"
    :class="variant"
    :id="anchor"
    data-nav-static
  >
    <div class="row">
      <div class="container-xl hero-main__overlay">
        <div class="hero-main__bg">
          <figure>
            <picture>
              <source
                media="(max-width: 767px)"
                :srcset="require('@/assets/images/home-page/' + imageUrl)"
              />
              <img
                :src="require('@/assets/images/home-page/' + imageUrl)"
                :alt="alt"
              />
            </picture>
          </figure>
          <h1 class="hero-main__title" v-if="titleShow === true">
            {{ title }}
          </h1>
        </div>
        <div class="hero-main__col col-sm-11 col-lg-5 col-xl-4">
          <img
            class="img-fluid logo"
            src="@/assets/logo/PNG/logo.png"
            alt="Bonnie Telpik"
          />
          <p class="hero-main__description">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// let anchor = 0;
export default {
  props: {
    imageUrl: String,
    title: String,
    titleShow: Boolean,
    anchor: String,
    alt: String,
    variant: String,
    description: String,
  },
  data: () => {
    return {};
  },
  mounted() {},
  created() {
    setTimeout(() => {
      // calculate the height of the hero
      // shared.isStickyNav();
    }, 0);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";

.hero-main {
  $gp: &;
  $max-height: 50vh;
  position: relative;
  background-color: $white;
  margin-top: 84px;

  &.container-fluid {
    padding: 0;

    .row {
      margin: 0;
    }
  }

  &--short {
    #{$gp}__bg {
      img {
        max-height: 350px;
      }
    }

    #{$gp}__col {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  &__bg {
    position: relative;
    --bs-gutter-x: 1rem;
    width: 100%;

    // height: $max-height;
    margin: 0 calc(var(--bs-gutter-x) * 0.5);

    @include media-breakpoint-up(xl) {
      height: 100%;
      // max-height: 75vh;
    }

    // background-color: pink;
    @include media-breakpoint-down(lg) {
      height: auto;
    }

    figure {
      height: 100%;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: auto;
      object-position: center top;

      @include media-breakpoint-up(lg) {
        height: 100%;
        // max-height: $max-height;
        object-fit: cover;
      }

      // @include media-breakpoint-up(xl) {
      //   height: 75vh;
      // }
    }
  }

  &__title {
    @include offsetTitle($white);
  }

  &__overlay {
    display: flex;
    justify-content: flex-start;

    &.container-xl {
      padding: 0;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      align-items: center;
    }
  }

  &__tagline {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.25rem;
    // font-family: Barlow Condensed;
    margin-top: -0.575rem;
    margin-bottom: 1.5rem;
  }

  //&__description {
  // font-family: $font-family-condense;
  // color: $teal;
  //}

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // text-align: center;
    padding: var(--card-spacing);

    // .row & {
    //   max-width: 350px;
    // }

    @include media-breakpoint-down(lg) {
      flex-direction: row;
      // margin-bottom: calc(var(--grid-spacing));
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      // margin-bottom: calc(var(--grid-spacing));
    }

    .logo {
      width: 100%;
      max-width: 250px;
      margin: 0 auto;
      margin-bottom: calc(var(--grid-spacing) * 2.5);

      @include media-breakpoint-down(lg) {
        margin-bottom: calc(var(--grid-spacing));
      }
    }

    .back-top {
      width: 30px;
      margin-top: 7rem;
    }

    p {
      font-size: 1.6rem;
    }
  }
}
</style>
