<template>
  <NavMenu></NavMenu>
  <router-view />
  <!-- <router-view v-slot="{ Component }">
    <component :is="Component" />
  </router-view> -->
  <FooterSec></FooterSec>
</template>

<script>
import FooterSec from "@/shared/footerSec.vue";
import NavMenu from "@/shared/navMenu.vue";

export default {
  name: "App",
  components: {
    FooterSec,
    NavMenu,
  },
};
</script>

<style lang="scss" scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3500ms ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
