<template>
  <section class="parentReviews">
    <h3 class="parentReviews__title">
      parent reviews<span class="material-symbols-outlined">
        sentiment_very_satisfied
      </span>
    </h3>
    <div class="reviewsBox">
      <ul class="reviewsBox__reviews">
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            Bonnie has been a great teacher for our son. He has been playing
            violin for five years, and she has been his teacher throughout that
            time. She knows how to handle little boy goofiness and make lessons
            fun, but she has also been very supportive as he has climbed into
            youth orchestra, district honors orchestra, and all-state orchestra.
            Highly recommend!<span class="reviewsBox__name"
              >— Christine H.</span
            >
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            My daughter has been taking violin lessons from Bonnie for over 2½
            years and still loves it. Bonnie is excellent with children. She is
            witty and playful, yet able to keep my daughter on track. Bonnie
            draws from her extensive background in theory and developmental
            techniques and custom designs her lessons according to the teaching
            needs of her students. For example, she utilizes flashcards with my
            daughter to teach note reading. She has creative terms for proper
            and improper hand placement and technique that kids love, Like a
            “pizza wrist” (bent wrist). In addition, Bonnie rewards her
            students’ progress with treats they enjoy. She also includes 2
            recitals per year to encourage practice and performance and hosts
            periodic “scale kingdoms” on Saturday as an impetus for young
            musicians to master their scales. We are so grateful for such an
            amazing violin teacher.
            <span class="reviewsBox__name">— Tasha J.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            My 11 year old son has been taking viola lessons with Bonnie for
            three years now and we have been very blessed to have found such a
            fantastic teacher. Right from the get go Bonnie was able to connect
            with my son. She is very attentive and patient. She has a wonderful
            quality of being able to assess her students abilities and interests
            and tailor the lessons towards her students needs and interests.
            Bonnie's energy , enthusiasm, and nurturing encouragement keeps my
            son motivated and inspires joyfulness in learning how to play. The
            best parts are hearing my son have a duet session with his teacher.
            My son will play the viola and she will accompany him on the violin.
            We are so grateful for Bonnie. My son looks forward to every lesson
            with her each week and is excited to come home and practice. I
            highly recommend her.
            <span class="reviewsBox__name">— Sarah V.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            Bonnie is brilliant! We have taken lessons with Bonnie for several
            years now and I’m constantly impressed with her ability to adapt and
            relate with my son. She is measured and deliberate where it matters,
            but also provides an environment that has plenty of room for fun and
            play. My son loves his lessons with Bonnie. He looks forward to them
            and talks about what he learned and the fun he had after. My son is
            12 and is indifferent on almost everything else he does, but he
            loves learning from Bonnie. As I said, Bonnie is brilliant and she
            deserves a medal for all the young lives she’s giving the gift of
            music to. Thank you Bonnie!
            <span class="reviewsBox__name">— Mark S.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            Bonnie has been a great gift for our son. She inspires him to want
            to do better, be better. He has true joy when playing with her,
            which translates to his playing at home and elsewhere. We are so
            grateful for Bonnie and her amazing talent as a teacher, mentor, and
            friend.
            <span class="reviewsBox__name">— Sabra B.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            n addition to being a talented musician, Bonnie is an amazing
            teacher! As an adult student, I'm glad that I found such a kind,
            patient and positive person as Bonnie to teach me how to play the
            fiddle. It took her no time at all to assess my abilities and
            interests, and then build a comprehensive plan that made lessons fun
            and challenging. Bonnie has a wonderful ability to relate to people.
            Her enthusiasm is contagious! I would highly recommend her as a
            teacher regardless of age or skill level.
            <span class="reviewsBox__name">— Dale H.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            When my daughter was 10, she decided she wanted to play the viola. I
            didn't want my daughter to be totally in the dark, as the other
            students in orchestra would have already been playing for a full
            school year. So, I reached out to Bonnie for private lessons. By the
            end of the third lesson, my daughter was playing a tune! Bonnie is
            so patient and encouraging with her students, and she is always
            smiling. Two years later, my daughter is still taking lessons with
            Bonnie, and she looks forward to her lesson every week!
            <span class="reviewsBox__name">— Laury S.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            My daughter has been taking weekly lessons from Bonnie for several
            years. One of the things parents of new violin students dislike is
            the tone new players have. To Bonnie's credit, the uncomfortable
            squeakiness that often accompanies beginning violin students is
            seldom present in her students' work, as is demonstrated in the
            student recitals. Bonnie's work on all aspects of technique has set
            her apart from other instructors my daughter has had, and has made
            me more comfortable hearing violin practice. Bonnie also takes time
            to discover other, related interests her students have and works
            those interests into lessons, helping keep students interested.
            Bonnie's supportive and encouraging instruction has helped my
            daughter enjoy the violin very much.
            <span class="reviewsBox__name">— Robert K.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            My 7-year-old daughter has had the pleasure of being one of Teacher
            Bonnie’s violin students for this past school year. We could not
            have asked for a better violin teacher! Bonnie is warm, kind, funny,
            supportive, and encouraging. She has a true gift to connect with her
            students and make it fun. She challenges them to play their best,
            build a good foundation with proper technique, and keep trying even
            if it is tricky. Thanks to Bonnie, my daughter spends most of her
            lesson smiling and leaves with a new level of confidence. My
            daughter’s enjoyment for playing violin stems from Teacher Bonnie’s
            positive teaching methods and warm personality. She has been a
            perfect fit for our family and we are so grateful to have found her.
            <span class="reviewsBox__name">— Rosa H.</span>
          </p>
        </li>
        <li class="reviewsBox__item">
          <p class="reviewsBox__quote">
            Our daughter has been a student of Bonnie's from 3rd grade up to
            high school. We knew immediately that Bonnie was the perfect fit.
            She is engaging, funny, and kind. Lessons are a highlight of the
            week! With Bonnie's encouragement, our daughter has joined several
            school and community orchestras. Bonnie is always willing to help
            with an extra lesson to help prepare for a chair audition. There is
            only one Bonnie, and we are so fortunate to have found her!
            <span class="reviewsBox__name">— Angeline A.</span>
          </p>
        </li>
      </ul>
    </div>
  </section>
</template>
<script></script>
<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom.scss";

.parentReviews {
  margin-top: 6rem;
  margin-bottom: 6rem;
  .material-symbols-outlined {
    font-size: 27px;
    color: $primary;
    margin-left: 0.5rem;
    @include media-breakpoint-up(sm) {
      font-size: 33px;
    }
  }
  &__title {
    color: $secondary;
    font-size: 3.25rem;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: -4px;
    @include media-breakpoint-up(sm) {
      font-size: 4rem;
    }
  }
}
.reviewsBox {
  height: 350px;
  overflow-y: scroll;
  touch-action: pan-y;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $primary;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
  background-color: $gray-200;
  padding: 1rem;

  &__reviews {
    list-style: none;
    margin: 2rem;
  }

  &__item {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__name {
    color: $secondary;
    margin: 0 0 0 1rem;
  }
}
</style>
