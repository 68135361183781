<template>
  <HeroSecondary
    :anchor="'teacher'"
    :class="'hero-main--short'"
    :titleShow="true"
    :title="'Teacher'"
    :imageUrl="'bonnie_three.jpg'"
    :alt="'Image of Child Playing a Violin'"
    :description="description"
  />
  <RequestForm />

  <section class="container-sm article-page__body">
    <!-- <BackToTop /> -->
    <div class="article-page__main-text">
      <p>
        As a teacher, Bonnie embodies qualities of patience, kindness, and
        enthusiasm. She believes that understanding how music works is
        essential, so she places emphasis on scales and incorporates discussions
        about music theory and history into each lesson, relating them to the
        repertoire being studied. Bonnie also fosters a sense of community and
        connection within her studio by offering opportunities for students to
        join the Scale Club, which holds fun group classes 3-4 times a year.
        Additionally, Bonnie organizes Spring and Winter recitals annually,
        providing students with goals to work towards and a chance to learn from
        their peers.
      </p>
      <p>
        Bonnie takes a personalized approach for each student, recognizing that
        no two students are the same. She utilizes various series, such as
        Suzuki, the Blue Book Series, and Adventures in ViolinLand, depending on
        the student’s age and prior experience. She supplements with other books
        or exercises as necessary. Students are encouraged to suggest pieces
        they would like to learn, resulting in a diverse repertoire ranging from
        SmashMouth’s “I’m a Believer” to Grieg’s “In the Hall of the Mountain
        King” to the theme music from Genshin Impact and other anime series.
        Bonnie creates a welcoming environment where students feel comfortable
        being themselves, asking questions, making mistakes, and growing. Her
        teaching not only provides students with a strong foundation in the
        fundamentals of music but also instills the value of hard work and the
        enjoyment of music.
      </p>
    </div>
    <div class="article-page__cta">
      <button
        data-bs-toggle="modal"
        data-bs-target="#requestForm"
        class="btn--primary card__cta"
      >
        SIGN UP OR LEARN MORE
      </button>
    </div>
    <ParentReviews />
  </section>
</template>

<script>
import HeroSecondary from "@/components/heroSecondary.vue";
import RequestForm from "@/components/requestForm.vue";
import ParentReviews from "@/components/parentReviews.vue";
// import BackToTop from "@/shared/backToTop.vue";

export default {
  name: "PerformerView",

  data() {
    return {
      description: ``,
    };
  },

  components: {
    HeroSecondary,
    RequestForm,
    ParentReviews,
    // BackToTop,
  },
  beforeCreate() {
    document.body.classList.add("article-page");
  },

  beforeUnmount() {
    document.body.classList.remove("article-page");
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vendors/bootstrap/custom";
</style>
